<template>
  <!-- 轮番图模块 -->
  <el-carousel :interval="5000" arrow="always" :height="bannerHeight+'px'">
    <el-carousel-item v-for="(item, index) in images" :key="index">
      <div class="image-contain" @click="blank_href(item.href)">
        <el-image class="elimage" :src="item.imageitem" />
        <!-- <el-image class="elimage" :src="imagetest" /> -->
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { getBannerList } from "@/api/banner";
export default {
  name: "Carousel",
  components: {},
  data() {
    return {
      imagetest: require("@/assets/new/test.jpg"),
      images: [],
      bannerHeight: 0,
      screenWidth: 0,
    };
  },
  computed: {},
  mounted() {
    this.getDataList({
      type:1
    });
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth
    this.bannerHeight = 450 / 1550 * this.screenWidth
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.bannerHeight = 450 / 1550 * this.screenWidth
    }
  },
  methods: {
    getDataList(params) {
      this.images = [];
      getBannerList(params)
        .then((response) => {
          console.log(response,"response");
          if (response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              this.images.push({
                imageitem: item.url,
                href: item.href
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    blank_href(item){
      console.log(item,"href");
      window.open(item,'_blank');
    }
  },
};
</script>
<style scoped lang="scss">
.image-contain {
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .elimage {
    // border: 1px solid red;
    width: 100%;
    height: inherit;
    // object-fit: fill;
    // transform: scale(1,1);
  }
}
</style>
